"use client"
import React from 'react'
import { useEffect } from 'react';
import { storeAssigned } from '../services/Methods/normalMethods';
import withRouteHOC from '../HOC/withRouterHOC';

function Indexpage(props) {
    const { searchParams, pathname } = props;

    useEffect(() => {
        if ((pathname === '/' || pathname?.includes('vercel.app')) && !searchParams.get('ssokey')) {
            if (process.env.NEXT_PUBLIC_COMING_SOON === 'true') {
                window?.location.assign(`/coming-soon`);
            } else {
                window?.location.assign(`/${storeAssigned()}`);
            }
        }
        if (pathname === '/coming-soon' && process.env.NEXT_PUBLIC_COMING_SOON === 'false') {
            window?.location.assign(`/${storeAssigned()}`)
        }
    }, [pathname]);

    return (
        <div></div>
    )
}

export default withRouteHOC(Indexpage);